<template>
  <div class="score overflow-auto">
    <base-header
      :title="$device.isMobile ? $t('成績單') : ''"
      :is-title-bold="true"
      :left-arrow="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="pageLoading"></loading-wrapper>
    <div v-else class="content scroll-bar">
      <transcript :transcript="transcript"></transcript>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Transcript from '@/components/Verification/Transcript.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import rankList from '@/json/rank.json';

export default {
  components: {
    BaseHeader,
    Transcript,
    LoadingWrapper,
  },
  data() {
    return {
      pageLoading: true,
      breadcrumb: [],
      rankList,
    };
  },
  computed: {
    level() {
      return this.rankList.find((rank) => {
        return rank.value === (this.transcript.rank || 0);
      }).label;
    },
    transcript() {
      return this.totalScores.filter((score) => {
        return this.$route.params.scoreId === score.id;
      })[0];
    },
    totalScores() {
      return this.$store.getters['verification/totalScores'];
    },
  },
  watch: {
    totalScores: {
      handler() {
        if (this.totalScores != null) {
          this.pageLoading = false;
          this.breadcrumb = [
            {
              name: this.$t('檢定'),
              enable: true,
              router: 'verification',
            },
            {
              name: this.$t('我的成績單'),
              enable: true,
              router: 'past-scores',
            },
            {
              name: `${this.level}${this.$t('棋力檢定')}`,
              enable: false,
            },
          ];
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getTotalScores();
  },
  methods: {
    getTotalScores() {
      return this.$store.dispatch('verification/getTotalScores');
    },
  },
};
</script>

<style lang="scss" scoped>
.score {
  .content {
    overflow: overlay;
    height: calc(100% - 56px);
    padding: 24px 16px;
  }
}
@media screen and (min-width: 768px) {
  .score .content {
    height: calc(100% - 80px);
    padding-bottom: 40px;
  }
}
</style>
