<template>
  <div class="transcript">
    <div class="transcript-header">
      <h2 class="title text-center">{{ level }}{{ $t('棋力檢定成績單') }}</h2>
      <p class="date text-center">{{ date }}</p>
      <div
        class="transcript-banner font-weight-bold text-center d-flex align-items-center justify-content-center"
      >
        <p class="score text-28">{{ transcript.totalScore }} {{ $t('分') }}</p>
        <p class="text-20 m-0">
          {{ transcript.isPass ? $t('已通過') : $t('未通過') }}
        </p>
      </div>
    </div>
    <div class="wrap overflow-hidden">
      <div class="content bg-white px-3 pt-3 pb-4">
        <template v-if="isNewTranscript">
          <div
            class="title bg-bgsection text-18 mb-2 font-weight-bold d-flex justify-content-between align-items-center"
            :class="$isJungo ? 'text-secondary' : 'text-primary'"
          >
            {{ $t('項目') }}：
            <span class="side-title text-14 font-weight-normal"
              >{{ $t('得分') }} / {{ $t('總分') }}</span
            >
          </div>
          <div class="list-wrapper">
            <div
              v-for="(transcriptItem, index) in newTranscriptList"
              :key="`${transcriptItem.type}${transcriptItem.fullMarks}`"
              class="list border-bottom d-flex justify-content-between align-items-center"
            >
              <div
                class="list-left d-flex justify-content-between align-items-center"
              >
                <div
                  class="list-count mr-3 bg-primary text-white d-flex justify-content-center align-items-center"
                >
                  {{ index + 1 }}
                </div>
                <h4>
                  {{ transcriptItem.game ? $t('下棋') : transcriptItem.type }}
                </h4>
              </div>
              <div class="list-right text-14">
                {{ transcriptItem.score }} / {{ transcriptItem.fullMarks }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="hasGameScore"
            class="title bg-bgsection text-18 mb-2 font-weight-bold d-flex justify-content-between align-items-center"
            :class="$isJungo ? 'text-secondary' : 'text-primary'"
          >
            {{ $t('下棋') }}：
            <span class="side-title text-14 font-weight-normal"
              >{{ $t('得分') }} / {{ $t('總分') }}</span
            >
          </div>
          <div v-if="hasGameScore" class="game list-wrapper">
            <div
              v-for="(game, index) in gameList"
              :key="`${game.type}${game.fullMarks}`"
              class="list border-bottom d-flex justify-content-between align-items-center"
            >
              <div
                class="list-left d-flex justify-content-between align-items-center"
              >
                <div
                  class="list-count mr-3 bg-primary text-white d-flex justify-content-center align-items-center"
                >
                  {{ index + 1 }}
                </div>
                <h4>{{ game.title }}</h4>
              </div>
              <div class="list-right text-14">
                {{ game.score }} / {{ game.fullMarks }}
              </div>
            </div>
          </div>

          <div
            v-if="hasTsumeScore"
            class="title bg-bgsection text-18 mb-2 font-weight-bold d-flex justify-content-between align-items-center"
            :class="$isJungo ? 'text-secondary' : 'text-primary'"
          >
            {{ $t('做題') }}：
            <span class="side-title text-14 font-weight-normal"
              >{{ $t('得分') }} / {{ $t('總分') }}</span
            >
          </div>
          <div v-if="hasTsumeScore" class="tsume list-wrapper">
            <div
              v-for="(tsume, index) in tsumeList"
              :key="`${tsume.type}${tsume.fullMarks}`"
              class="list border-bottom d-flex justify-content-between align-items-center"
            >
              <div
                class="list-left d-flex justify-content-between align-items-center"
              >
                <div
                  class="list-count mr-3 bg-primary text-white d-flex justify-content-center align-items-center"
                >
                  {{ index + 1 }}
                </div>
                <h4>{{ tsume.type }}</h4>
              </div>
              <div class="list-right text-14">
                {{ tsume.score }} / {{ tsume.fullMarks }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import rankList from '@/json/rank.json';
import day from 'dayjs';

export default {
  props: {
    transcript: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      rankList,
    };
  },
  computed: {
    hasGameScore() {
      return this.gameList.length;
    },
    hasTsumeScore() {
      return this.tsumeList.some((tsume) => {
        return tsume.fullMarks;
      });
    },
    level() {
      return this.rankList.find((rank) => {
        return rank.value === (this.transcript.rank || 0);
      }).label;
    },
    date() {
      return day(this.transcript.updatedAt).format('YYYY-MM-DD');
    },
    gameList() {
      return Object.entries(this.transcript.game)
        .map(([key, value]) => {
          let title = '';
          switch (key) {
            case 'win':
              title = this.$t('勝負');
              break;
            case 'vision':
              title = this.$t('大局');
              break;
            case 'fuseki':
              title = this.$t('佈局');
              break;
            case 'fight':
              title = this.$t('戰鬥');
              break;
            case 'endgame':
              title = this.$t('官子');
              break;
            case 'comprehensive':
              title = this.$t('綜合');
              break;
            case 'goodRate':
              title = this.$t('好棋率');
              break;
            default:
              title = this.$t('其他');
              break;
          }
          return {type: key, title, ...value, game: true};
        })
        .filter((gameScore) => {
          return gameScore?.fullMarks;
        });
    },
    tsumeList() {
      return this.transcript.tsume;
    },
    isNewTranscript() {
      return this.gameList.every((game) => {
        if ((game.type !== 'win' && !game.fullMarks) || game.type === 'win') {
          return true;
        } else {
          return false;
        }
      });
    },
    newTranscriptList() {
      return [...this.tsumeList, ...this.gameList];
    },
  },
};
</script>

<style lang="scss" scoped>
.transcript {
  .transcript-header {
    .title {
      margin-bottom: 8px;
    }
    .date {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .transcript-banner {
    background: $primary;
    border-radius: 10px 10px 0 0;
    flex-direction: column;
    color: #ffffff;
    padding: 24px 0;
    .score {
      margin-bottom: 4px;
    }
  }
  .wrap {
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
    .title {
      border-radius: 10px;
      padding: 5px 16px;
    }
    .side-title {
      color: $font-grayscale-1;
    }
  }
  .list-wrapper:not(:last-child) {
    margin-bottom: 30px;
  }
  .list {
    padding: 10px 16px 10px 8px;
    i {
      font-size: 26px;
    }
    .list-count {
      width: 24px;
      height: 24px;
      border-radius: 6px;
    }
    .list-right {
      color: $font-grayscale-1;
    }
  }
}

@media screen and (min-width: 768px) {
  .transcript {
    max-width: 720px;
    margin: 0 auto;
    .transcript-header {
      border-radius: 10px 10px 0 0;
      background: $bgsection;
      padding: 40px 118px 37px;
      box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
      .title,
      .date {
        font-weight: normal;
        font-size: 16px;
        margin: 0;
      }
    }
    .transcript-banner {
      border-radius: 10px;
      background: white;
      color: black;
      flex-direction: row;
      padding: 56px 0;
      margin-top: 14px;
      .score {
        margin-right: 8px;
      }
      p {
        font-weight: bold;
        margin: 0;
        font-size: 40px !important;
      }
    }
    .wrap {
      background: white;
      padding: 24px 146px 40px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .transcript {
    max-width: 1140px;
    .transcript-header {
      padding: 38px 190px;
    }
    .wrap {
      padding: 24px 215px 40px;
    }
  }
}
</style>
